import React, { useState, useEffect } from "react";
import { elementScrollIntoView } from "seamless-scroll-polyfill";

const ScrollToTop = (props) => {
  const [show, setShow] = useState(props.top ? false : true);

  const handleScroll = () => {
    if (window.pageYOffset > props.top) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  const handleClick = () => {
    elementScrollIntoView(document.querySelector("#top"), {
      behavior: "smooth",
      block: "start"
    });
  };

  useEffect(() => {
    if (props.top) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });

  return (
    <div>
      {show && (
        <button
          onClick={handleClick}
          className="scroll-to-top"
          aria-label="scroll to top"
          style={{
            display: "block",
            zIndex: "1000",
            position: "fixed",
            bottom: "100px",
            right: "20px",
          }}
        >
          {props.children}
        </button>
      )}
    </div>
  );
};

export default ScrollToTop;

import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "../common.css";
import ScrollToTop from "./scroll_to_top";
import up_arrow from "../images/up_arrow.png";

const layout = ({ children }) => (
  <>
    <Header />
    {children}
    <ScrollToTop top="100">
      <div className="bg-opacity-50 bg-white hover:bg-blue-100 flex flex-col items-center py-1">
        <img src={up_arrow} class="inline-block mb-1" alt="↑" />
        <span style={{ writingMode: "vertical-rl" }}>TOP PAGE</span>
      </div>
    </ScrollToTop>
    <Footer />
  </>
);

export default layout;
